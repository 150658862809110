<!-- eslint-disable vue/require-v-for-key -->

<template>
    <div :class="'item'+item.id" :style="marginStyle">
        <div>
            <div class="row m-0">
                <div v-if="item.name" class="col m-0 name" :style="nameStyle">
                    <span v-if="item.name">{{ item.name }}</span> <img v-if="item.vegano" class="type vegan" src="@/assets/icons/vegan.svg" :style="iconStyle"><img v-if="item.celiaco" class="type celiac" src="@/assets/icons/gluten-free.svg" :style="iconStyle">
                </div>
                <div v-if="item.price" class="col-3 m-0 price" :style="priceStyle">
                    <span v-if="item.price"> ${{ item.price }}</span>
                </div>
            </div>
            <div class="row m-0">
                <div class="col-9 m-0 description" :style="descStyle">
                    <span v-if="item.description">{{ item.description }}</span>
                </div>
            </div>
        </div>
    </div>
    <hr v-if="item.items.length>1">
    <div v-if="hasChildren" class="node">
      <MenuItem
        v-for="child in item.items"
        :item="child"
        :spacing="0"
        :fontsize="fontsize - 0.1"
      />
    </div>
</template>

<script>
    export default {
        name: 'MenuItem',
        props:
        {
            item: {
                type: Object,
                required: true
            },
            spacing: {
                type: Number,
                default: 4
            },
            fontsize: {
                type: Number,
                default: 1.1
            }
        },
        computed: {
            marginStyle() {
                return {
                    'margin-bottom': `0.5vh`,
                    'margin-top': `${this.spacing}vh`,
                }
            },
            nameStyle() {
                return {
                    'font-size': `${this.fontsize}rem!important`,
                    'margin-top': `${this.fontsize}rem!important`,
                    'font-weight': 'bold',
                    'height': 'fit-content'
                }
            },
            priceStyle() {
                return {
                    'font-size': `${this.fontsize}rem!important`,
                    'margin-top': `${this.fontsize}rem!important`,
                    'text-align': this.item.name? 'right': 'center',
                    'height': 'fit-content'
                }
            },
            descStyle() {
                return {
                    'font-size': `${this.fontsize-0.1}em!important`,
                }
            },
            iconStyle() {
                return {'height': `${this.fontsize+0.4}rem!important`}
            },
            hasChildren() {
                const { items } = this.item
                return items && items.length > 0
            }
        }
    }
</script>