<template>
    <div class="landing">
        <div class="topper">
          <div class="i_01 col"></div>
          <div class="i_02 col"></div>
          <div class="i_03 col"></div>
        </div>
        <MenuPage />
        <div class="footer">
          <div class="i_01 col"></div>
          <div class="i_02 col"></div>
          <div class="i_03 col"></div>
        </div>
    </div>
  </template>
  
<script>
  import MenuPage from './MenuPage.vue'
  
  export default {
    name: 'LandingPage',
    components: {
      MenuPage
    },
};
</script>

<style>
</style>
  