import axiosInstance from './http-common';
import router from '../router';
// import vtoast from '../components/admin/snippets/VToast.vue';

const setup = () => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = JSON.parse(localStorage.getItem('user') ? localStorage.getItem('user') : null);
            if (token) {
                config.headers['Authorization'] = token;
            }
            return config;
        },
        (error) => {
            // console.log({ error });
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            // console.log('interceptors - ', res);
            return res;
        },
        async(err) => {
            // console.log('interceptors - ', { err });
            const originalConfig = err.config;

            if (!originalConfig.url.includes("/login") && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const refreshToken = JSON.parse(localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken') : null);
                        const rs = await axiosInstance.post("/refreshToken", {
                            refreshToken
                        });

                        if (rs.data) {
                            const { token, refreshToken } = rs.data;
                            if (token) {
                                localStorage.setItem('user', JSON.stringify(token));
                                localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
                            }
                            return axiosInstance(originalConfig);
                        }
                    } catch (_error) {
                        if (_error && _error.response && _error.response.status == 403) {
                            localStorage.removeItem('user');
                            localStorage.removeItem('refreshToken');
                            // vtoast.show({ message: 'Sesión finalizada.' });
                            router.push('/login');
                        }
                    }
                } else if ((err.response.status === 403 || err.response.status === 500)) {
                    originalConfig._retry = true;
                    localStorage.removeItem('user');
                    localStorage.removeItem('refreshToken');
                    // vtoast.show({ message: 'Sesión finalizada.' });
                    router.push('/login');
                }
            } else if (originalConfig.url.includes("/login") && err.response) {
                // console.log('interceptors - ', { err });
                return err.response;
            }
        }
    );
};

export default setup;