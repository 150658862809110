import { createApp } from 'vue';
import { createStore } from 'vuex';
import { auth } from "./services/auth.module";
import setupInterceptor from './api/interceptor';
import router from './router';
import App from './App.vue';
import { vMaska } from 'maska';

// Vuetify
// import 'vuetify/styles'
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import VueCookies from 'vue-cookies';

import '@/assets/bootstrap/bootstrap.min.css';
import '@/assets/bootstrap/bootstrap.bundle.min.js';

import '@/assets/scss/admin.scss';
import '@/assets/scss/landing.scss';
import '@/assets/scss/snippets.scss';




const app = createApp(App);

const store = createStore({
    modules: {
        auth
    }
});

setupInterceptor(store);
app.use(store);


app.use(router);

app.use(VueCookies, {});


const vuetify = createVuetify({
    components,
    directives,
});

app.use(vuetify);
// app.config.compilerOptions.isCustomElement = (tag) => tag.includes('-')

app.directive("maska", vMaska);

app.use({
    load: {
        key: '',
    },
}).mount('#app');