import httpCommon from '../api/http-common';

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
    login(user) {
        return httpCommon
            .post(API_URL + '/login', {
                user: user.user,
                password: user.password
            })
            .then(response => {
                if (response.data.ok) {
                    localStorage.setItem('user', JSON.stringify(response.data.token));
                    localStorage.setItem('refreshToken', JSON.stringify(response.data.refreshToken));
                }

                return response.data;
            });
    }

    logout() {
        return httpCommon
            .delete(API_URL + `/refreshToken/${ JSON.parse(localStorage.getItem('refreshToken')) }`)
            .then(response => {
                if (response.status == 200) {
                    localStorage.removeItem('user');
                    localStorage.removeItem('refreshToken');
                }
                return response.data;
            });
    }

    register(user) {
        return httpCommon.post(API_URL + '/users', {
            name: user.username,
            email: user.email,
            password: user.password,
            role: user.role
        });
    }
}

export default new AuthService();