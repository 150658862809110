<template>
  <section class="menu" id="menu">
    <div class="navMenu mobile">
      <select v-model="selectedMenu" @change="scrollToTitle(selectedMenu.id)">
        <option v-for="item in items" :key="item.id" :value="item">{{ item.name }}</option>
      </select>
    </div>
    <div class="navMenu desktop">
      <div class="panel">
        <div class="panel-container" ref="container">
          <div class="logo">
              <img src="@/assets/img/Footer02.png">
          </div>
          <div class="items">
              <button  :class="'menuButton'+item.id" v-for="item in items" @click="scrollToTitle(item.id)">{{item.name}}<br>{{item.description}}</button>
              <br>
          </div>
        </div>
      </div>
    </div>
      <div class="panel">
        <div class="panel-container" ref="scrollContainer" @scroll="onScroll">
          <div v-for="item in items" :key="item.id" >
            <div class="menuPage" :id="'page'+item.id" >
            <div :ref="'page'+item.id" class="menuItems">
              <MenuItem v-for="itemCh in item.items" :item="itemCh" :key="itemCh.id"></MenuItem>
            </div>
            </div>
            <hr>
          </div>
        </div>
      </div>


  </section>
</template>
<script>
    import MenuItem from './MenuItem.vue';
    import MenuService from '@/services/dataServices/menu.service';
    export default {
      name: 'MenuPage',
      components:{
        MenuItem
      },
      data(){
        return {
          items:[],
          selectedMenu: null
        }
      },
      methods:{
        getSelectedMenu(){
          const time = new Date().getHours();
          var defaultItem = this.items[0];
          var biggerTime = 0;
          for (const item of this.items) {
            for (const itemTime of item.startTimes) {
              if (time >= parseInt(itemTime.value) && parseInt(itemTime.value) >= biggerTime) {
                defaultItem = item;
                biggerTime = parseInt(itemTime);
              }
            }
          }
          this.scrollToTitle(defaultItem.id)
          return defaultItem;
        },
        scrollToTitle(itemId) {
          
          let buttons = document.querySelectorAll('[class*="menuButton"]');
          for(const button of buttons){
            if(button.classList.contains('menuButton'+itemId)){
              button.classList.add("active")
            } else {
              button.classList.remove("active")
            }
          }

          this.selectedMenu = this.items.find(item => item.id === itemId)

          let target = document.getElementById("page"+itemId);
          if (target) {
            window.scrollTo(0, target.offsetTop + 1)
          }
        },
        onScroll() {
          const scrollTop = window.scrollY;

          for (const item of this.items) {
            const section = this.$refs['page' + item.id][0];
            const sectionTop = section.offsetTop;
            const sectionHeight = section.offsetHeight;

            if (scrollTop >= sectionTop && scrollTop < sectionTop + sectionHeight) {
              document.querySelectorAll('[class*="menuButton"]').forEach(element => element.classList.remove('active'));
              document.querySelector('[class*="menuButton'+item.id+'"]').classList.add("active")
              this.selectedMenu = item;
              break;
            }
          }
        }
      },
      async mounted(){
        try {
            MenuService.getActive().then((res) => {
              this.items = JSON.parse(JSON.stringify(res[0].menujson));

              this.$nextTick(() => {
                for (const item of this.items) {
                  const menuPage = this.$refs['page' + item.id][0];
                  menuPage.style.height = Math.ceil(menuPage.offsetHeight / 2) * 1.05 + 'px';
                  menuPage.classList.add('column-count-2');
                }

                this.selectedMenu = this.getSelectedMenu();
              });
            }).catch((e) => {
              this.items = [{ id: '0', name: 'MENU NO DISPONIBLE, INTENTE MAS TARDE', price: '', description: '', startTimes:[{'value':'00'}] }];
            });
            window.addEventListener("scroll", this.onScroll, true)
          } catch (e) {
            console.error('An error occurred:', e);
          }
      },
    }
</script>